import React from 'react'
import { withRouter } from 'react-router'
import CommentListView from './CommentListView'
import DateForm from './DateForm'
import Data from './Data'
import Util from './Util'


class DateSearch extends React.Component {

  constructor (props) {
    super(props)
    this.state = {
      'records': [],
      'fromDate': props.match.params.fromDate || '',
      'toDate': props.match.params.toDate || ''
    }
    if (this.state.toDate === '') {
      this.state.selectedOption = 'date'
    } else {
      this.state.selectedOption = 'period'
    }
    if (props.match.params.isUnique === 'true') {
      this.state.isUnique = true
    } else {
      this.state.isUnique = false
    }
  }

  componentWillMount() {
    this.getDataFromPeriod(this.state.fromDate, this.state.toDate, this.state.isUnique)
  }

  componentWillReceiveProps (nextProps) {
    let isUnique
    if (nextProps.match.params.isUnique === 'true') {
      isUnique = true
    } else {
      isUnique = false
    }
    const start = nextProps.match.params.fromDate || ''
    const end = nextProps.match.params.toDate || ''
    if (start === this.state.fromDate
      && end === this.state.toDate
      && isUnique === this.state.isUnique) {
      return
    } else {
      this.getDataFromPeriod(start, end, isUnique)
    }
  }

  getDataFromPeriod (start, end, isUnique) {
    const startNum = Number(start)
    const endNum = Number(end)
    if (isNaN(startNum) || startNum === 0) {
      this.setState({'records': [], 'fromDate': '', 'toDate': '', 'selectedOption': 'date', 'isUnique': true})
    } else {
      if (isNaN(endNum) || endNum === 0) {
        Data.getDataFromPeriod(start, start, isUnique,
          (e) => this.setState({'records': e, 'fromDate': start, 'toDate': '',
            'selectedOption': 'date', 'isUnique': isUnique}))
      } else {
        Data.getDataFromPeriod(start, end, isUnique,
          (e) => this.setState({'records': e, 'fromDate': start, 'toDate': end,
            'selectedOption': 'period', 'isUnique': isUnique}))
      }
    }
  }

  render () {
    let titleStr = ''
    if (this.state.fromDate !== '') {
      if (this.state.selectedOption === 'date') {
        titleStr = '裁判日 ' + Util.plusWareki(this.state.fromDate)
      } else {
        titleStr = '期間 ' + Util.plusWareki(this.state.fromDate)
          + '～' + Util.plusWareki(this.state.toDate)
      }
    }
    return <div id="datesearch">
      <DateForm fromDate={this.state.fromDate} toDate={this.state.toDate}
        isUnique={this.state.isUnique}
        onSubmit={ (start, end, isUnique) => {
          let uniqueStr
          if (isUnique) {
            uniqueStr = 'true'
          } else {
            uniqueStr = 'false'
          }
          if (start !== '') {
            if (end !== '') {
              this.props.history.push(`/datesearch/${uniqueStr}/${start}/${end}`)
            } else {
              this.props.history.push(`/datesearch/${uniqueStr}/${start}`)
            }
          }
        }}/>
      <CommentListView records={ this.state.records }
                       title={ titleStr } />
    </div>
  }
}

export default withRouter(DateSearch)
