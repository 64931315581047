import React from 'react'
import { Form, FormGroup, Label, Input, Button, Row, Col } from 'reactstrap'
import Util from './Util'


class DateForm extends React.Component {

  constructor (props) {
    super(props)
    const start = this.addHyphen(props.fromDate)
    const end = this.addHyphen(props.toDate)
    this.state = {
      'start': start,
      'end': end,
      'startWareki' : this.toWareki(start),
      'endWareki' : this.toWareki(end),
      'matome': props.isUnique,
      'seireki': false
    }
    if (this.state.end === '') {
      this.state.selectedOption = 'date'
    } else {
      this.state.selectedOption = 'period'
    }
  }

  addHyphen (dateStr) {
    if (dateStr.length !== 8) {
      return ''
    }
    return `${dateStr.slice(0,4)}-${dateStr.slice(4,6)}-${dateStr.slice(6,8)}`
  }

  componentWillReceiveProps (nextProps) {
    const start = this.addHyphen(nextProps.fromDate)
    const end = this.addHyphen(nextProps.toDate)
    const startWareki = this.toWareki(start)
    const endWareki = this.toWareki(end)
    const matome = nextProps.isUnique
    let selectedOption
    if (end === '') {
      selectedOption = 'date'
    } else {
      selectedOption = 'period'
    }
    this.setState({start, end, startWareki, endWareki, matome, selectedOption})
  }

  // checkKeyPress (e) {
  //   // エンターキーが押された場合のみ処理
  //   if (e.charCode === 13) {
  //     this.props.onSubmit(this.state.keyword)
  //   }
  // }

  handleOptionChange (changeEvent) {
    this.setState({
      'selectedOption': changeEvent.target.value
    })
  }

  handleMatomeChange (e) {
    this.setState({
      'matome': !this.state.matome
    })
  }

  toWareki (seirekiStr) {
    const eightDigit = seirekiStr.replace(/-/g, '')
    return Util.toWarekiShort(eightDigit)
  }

  toSeireki (warekiStr) {
    return Util.toSeirekiHyphen(warekiStr)
  }

  checkWareki (e) {
    const seireki = Util.toSeirekiHyphen(e.target.value)
    if (seireki !== '') {
      if (e.target.name === 'startDateWareki') {
        this.setState({'start': seireki, 'startWareki': e.target.value})
      } else {
        this.setState({'end': seireki, 'endWareki': e.target.value})
      }
    } else {
      if (e.target.name === 'startDateWareki') {
        this.setState({'start': '', 'startWareki': e.target.value})
      } else {
        this.setState({'end': '', 'endWareki': e.target.value})
      }
    }
  }

  submit () {
    let start = this.state.start.replace(/-/g, '')
    let end = this.state.end.replace(/-/g, '')
    if (this.state.selectedOption === 'date') {
      end = ''
    }
    this.props.onSubmit(start, end, this.state.matome)
  }

  render () {
    let startLabel
    if (this.state.selectedOption === 'date') {
      startLabel = '裁判日'
    } else {
      startLabel = '開始'
    }
    return <Form onSubmit={ e => e.preventDefault() }>
        <Row form>
        <FormGroup tag="fieldset">
          <FormGroup check inline>
            <Label check>
              <Input type="radio" name="radio1" value="date"
                checked={this.state.selectedOption==='date'}
                onChange={e => this.handleOptionChange(e)}/>{' '}
                日付
            </Label>
          </FormGroup>
          <FormGroup check inline>
            <Label check>
              <Input type="radio" name="radio1" value="period"
                checked={this.state.selectedOption==='period'}
                onChange={e => this.handleOptionChange(e)}/>{' '}
                期間
            </Label>
          </FormGroup>
          <FormGroup check inline>
            <Label check>
              <Input type="checkbox"
                checked={this.state.matome===true}
                onChange={e => this.handleMatomeChange(e)}/>{' '}
                解説が同一のときは一項目にする
            </Label>
          </FormGroup>
          <FormGroup check inline>
            <Label check>
              <Input type="checkbox"
                checked={this.state.seireki===true}
                onChange={e => this.setState({'seireki': !this.state.seireki})}/>{' '}
                西暦で入力
            </Label>
          </FormGroup>
        </FormGroup>
        </Row>
        <Row form>
          <Col md={7}>
            {this.state.seireki===true &&
            <FormGroup>
              <Label for="startDate">{ startLabel }</Label>
              <Input
                type="date"
                name="startDate"
                id="startDate"
                placeholder="date placeholder"
                onChange={ e =>
                    this.setState({'start': e.target.value,
                                   'startWareki': this.toWareki(e.target.value)}) }
                value={this.state.start}
              />
            </FormGroup>}
            {this.state.seireki===false &&
            <FormGroup>
              <Label for="startDateWareki">{ startLabel }</Label>
              <Input
                type="text"
                name="startDateWareki"
                id="startDateWareki"
                placeholder="(例)H31.2.16"
                onChange={ e => this.checkWareki(e) }
                value={ this.state.startWareki }
              />
            </FormGroup>}
          </Col>
          {this.state.selectedOption==='period' &&
            <Col md={7}>
              {this.state.seireki===true &&
              <FormGroup>
                <Label for="endDate">終了</Label>
                <Input
                  type="date"
                  name="endDate"
                  id="endDate"
                  placeholder="date placeholder"
                  onChange={ e =>
                    this.setState({'end': e.target.value,
                                   'endWareki': this.toWareki(e.target.value)}) }
                  value={this.state.end}
                />
              </FormGroup>}
              {this.state.seireki===false &&
              <FormGroup>
                <Label for="endDateWareki">終了</Label>
                <Input
                  type="text"
                  name="endDateWareki"
                  id="endDateWareki"
                  placeholder="(例)H31.4.1"
                  onChange={ e => this.checkWareki(e) }
                  value={ this.state.endWareki }
                />
              </FormGroup>}
            </Col>
          }
          <Col md={5} className="align-self-end">
            <FormGroup>
            <Button onClick={ e => this.submit() }>検索</Button>
            </FormGroup>
          </Col>
        </Row>
      </Form>
  }
}

export default DateForm
