import React from 'react'
import { Form, Button, Row, Col } from 'reactstrap'
import KeywordInput from './KeywordInput'


class KeywordForm extends React.Component {

  constructor (props) {
    super(props)
    this.state = {'keyword': props.keyword}
  }

  componentWillReceiveProps (nextProps) {
    this.setState({keyword: nextProps.keyword})
  }

  onChange (e) {
    this.setState({'keyword': e.value})
  }

  checkKeyPress (e) {
    // エンターキーが押された場合のみ処理
    if (e.charCode === 13) {
      e.target.blur() // フォーカスを外す
      this.props.onSubmit(this.state.keyword)
    }
  }

  render () {
    return <Form onSubmit={ e => e.preventDefault() }>
        <Row form>
          <Col md={12}>
            <KeywordInput keyword={ this.state.keyword }
              onChange={ e => this.onChange(e) }
              onKeyPress={ e => this.checkKeyPress(e) }/>
          </Col>
          <Col md={5}>
            <Button onClick={ e => this.props.onSubmit(this.state.keyword) }>検索</Button>
          </Col>
        </Row>
      </Form>
  }
}

export default KeywordForm
