import React from 'react'
import { withRouter } from 'react-router'
import CommentListView from './CommentListView'
import IDForm from './IDForm'
import Data from './Data'


class IDSearch extends React.Component {

  constructor (props) {
    super(props)
    this.state = {
      'records': [],
      'caseid': props.match.params.caseid || ''
    }
  }

  componentWillMount() {
    this.getDataFromCaseID(this.state.caseid)
  }

  componentWillReceiveProps (nextProps) {
    const caseid = nextProps.match.params.caseid || ''
    if (caseid !== this.state.caseid) {
      this.getDataFromCaseID(caseid)
    }
  }

  isValid (caseid) {
    const regexp = new RegExp(/.+裁-(明治|大正|昭和|平成)\d+年（.+）第\d+号/)
    return regexp.test(caseid)
  }

  getDataFromCaseID (caseid) {
    if (this.isValid(caseid)) {
      Data.getDataFromCaseID(caseid,
        (e) => this.setState({'records': e, 'caseid': caseid}))
    } else {
      this.setState({'records': []})
    }
  }

  render () {
    let titleStr
    if (this.isValid(this.state.caseid)) {
      titleStr = this.state.caseid
    } else {
      titleStr = ''
    }
    return <div id="idsearch">
      <IDForm caseid={this.state.caseid} onSubmit={ (caseid) => {
          this.props.history.push('/idsearch/' + caseid)
        }}/>
      <CommentListView records={ this.state.records }
                       title={ titleStr } />
    </div>
  }
}

export default withRouter(IDSearch)
