import { withOAuth } from 'aws-amplify-react'
import React from 'react'
import { Row, Col, Button } from 'reactstrap'


class OAuthButton extends React.Component {
  render() {
    return (
      <Row className="justify-content-center">
        <Col xs="auto" className="pt-2">
          <Button color="secondary" onClick={this.props.OAuthSignIn}>
            サインインページへ移動
          </Button>
        </Col>
      </Row>
    )
  }
}

export default withOAuth(OAuthButton)
