import Auth from '@aws-amplify/auth'
import S3 from 'aws-sdk/clients/s3'
import React from 'react'
import classNames from 'classnames'
import { generate as shortid } from 'shortid'
import Dropzone from 'react-dropzone'
import { Button, Row, Col } from 'reactstrap'
import Util from './Util'

class UploadWord extends React.Component {

  constructor (props) {
    super(props)
    this.state = {
      files: [],
      objects: [],
    }
  }

  getS3Obj () {
    const p = new Promise((resolve, reject) => {
      Auth.currentCredentials()
        .then(credentials => {
          const s3= new S3({
            region: 'ap-northeast-1',
            // endpoint: process.env.REACT_APP_DYNAMODB_ENDPOINT,
            // sslEnabled: false,
            // sslEnabled: true,
            params: {Bucket: 'net.hanketsu.contents'},
            credentials: Auth.essentialCredentials(credentials),
          })
          resolve(s3)
        })
        .catch(err => reject(err))
    })
    return p
  }

  listObjects (s3) {
    const p = new Promise((resolve, reject) => {
        s3.listObjects({Delimiter: '/', Prefix: 'word/'}, function(err, data) {
        if (err) {
          reject(err)
        } else {
          resolve(data.Contents)
        }
      })
    })
    return p
  }

  putObject (s3) {
    this.state.files.forEach( (file, index, array) => {
      array[index].status = '： アップロード中...'
      this.setState({files: array})
      s3.putObject({Key: 'word/' + Util.getTimeStamp() + file.obj.name, Body: file.obj},
        (err, data) => {
            if (err) {
              array[index].status = '： エラーが発生しました'
              this.setState({files: array})
            } else {
              array[index].status = '： アップロード完了'
              this.setState({files: array})
            }
        }
      )
    })
  }

  componentWillMount () {
    this.getS3Obj()
      .then( s3 => this.listObjects(s3) )
      .then( result => this.setState({objects: result}))
  }

   onDrop = (acceptedFiles, rejectedFiles) => {
     if (acceptedFiles.length !== 0) {
       this.setState({files: acceptedFiles.map( e => {return {obj: e, status: ''}})})
     }
   }

   upload () {
     this.getS3Obj()
       .then( s3 => this.putObject(s3) )
   }

   render() {
    return (<div><Row><Col xs="auto">
      <Dropzone onDrop={this.onDrop}
        accept="application/vnd.openxmlformats-officedocument.wordprocessingml.document">
        {({getRootProps, getInputProps, isDragActive}) => {
          return (
            <div
              {...getRootProps()}
              className={classNames('drop', {'drop--isActive': isDragActive})}
            >
              <input {...getInputProps()} />
              <Row className="justify-content-center">
                <Col xs="auto">
                  { this.state.files.length === 0 &&
                    <div className="p-4">ファイルをこのエリアにドラッグするか、<br/>クリックして下さい</div>
                  }
                  { this.state.files.length !== 0 && <div>
                      <div className="p-4">アップロードするファイル：{this.state.files.length}件</div>
                      {this.state.files.map(e => {
                        return <div key={shortid()} className="px-4">{e.obj.name}
                          {e.status}</div> })}
                    </div>
                  }
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col xs="auto">
                  <img src="/upload.png" alt="upload"/>
                </Col>
              </Row>
            </div>
          )
        }}
      </Dropzone></Col>
      </Row>
      <Row className="mt-2">
        <Col xs="auto">
          <Button color="primary" onClick={e => this.upload() }>アップロード</Button>
        </Col>
        <Col xs="auto">
          <Button onClick={e => this.setState({files:[]}) }>キャンセル</Button>
        </Col>
      </Row>
    </div>)
  }
}
// <Row>
//   <Col>
//     {this.state.objects.map(e => { return <div key={shortid()} className="px-4">{e.Key}</div> })}
//   </Col>
// </Row>

export default UploadWord
