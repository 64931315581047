import React from 'react'
import { Navbar, Nav, NavItem, NavbarToggler, Collapse } from 'reactstrap'
// import classNames from 'classnames'
import { Switch, Route, NavLink } from 'react-router-dom'
import KeywordSearch from './KeywordSearch'
import DateSearch from './DateSearch'
import IDSearch from './IDSearch'
import Commentary from './Commentary'


class Search extends React.Component {

  constructor (props) {
    super(props)
    this.state = {collapsed: true}
  }

  toggleNavbar () {
    this.setState({
      collapsed: !this.state.collapsed
    })
  }

  render () {
    return <div id="search">
      <Navbar color="light" light expand="sm">
        検索方式：
        <NavbarToggler onClick={e => this.toggleNavbar()} className="mr-2" />
        <Collapse isOpen={!this.state.collapsed} navbar>
          <Nav navbar>
            <NavItem>
              <NavLink to="/keysearch" className="nav-link" activeClassName="nav_active">キーワード</NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/datesearch" className="nav-link" activeClassName="nav_active">裁判日</NavLink>
            </NavItem>
            <NavItem>
              <NavLink to="/idsearch" className="nav-link" activeClassName="nav_active">事件番号</NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
      <Switch>
        <Route path="/keysearch/:keyword" component={ KeywordSearch } />
        <Route path="/keysearch" component={ KeywordSearch } />
        <Route path="/datesearch/:isUnique/:fromDate/:toDate" component={ DateSearch } />
        <Route path="/datesearch/:isUnique/:fromDate" component={ DateSearch } />
        <Route path="/datesearch" component={ DateSearch } />
        <Route path="/idsearch/:caseid" component={ IDSearch } />
        <Route path="/idsearch" component={ IDSearch } />
        <Route path="/comment/:objKey" component={ Commentary } />
      </Switch>
    </div>
  }
}

export default Search
