import * as moment from 'moment'


class Util {
  static counter (init) {
    var value = init - 1
    return (_) => {
      value = value + 1
      return value
    }
  }

  static datecheck () {
    var a = moment('19740501')
    a.utcOffset("+09:00")
    var b = a.clone()
    b.subtract(1, 'd')
    console.log(a.format('YYYYMMDD'))
    console.log(b.format('YYYYMMDD'))
    console.log(Number(''))
  }

  static getTimeStamp () {
    return moment().format('YYMMDDHHmmssSSS')
  }

  static _toWareki (eightDigit) {
    let warekiObj = {seireki: '', gengou: '', genMark: '', year: '', month: '', day: ''}
    if (eightDigit.length !== 8 ) {
      return null
    }
    let t = Number(eightDigit)
    if (isNaN(t)) {
      return null
    }
    let gengou = ['西暦', '明治', '大正', '昭和', '平成', ]
    let genMark = ['', 'M', 'T', 'S', 'H', ]
    let seireki = Number(eightDigit.slice(0,4))
    let month = Number(eightDigit.slice(4,6))
    let day = Number(eightDigit.slice(6,8))
    let year
    let result
    if (t < 18680908) {
      result = 0
      year = seireki
    } else if (t < 19120730) {
      result = 1
      year = seireki - 1867
    } else if (t < 19261225) {
      result = 2
      year = seireki - 1911
    } else if (t < 19890108) {
      result = 3
      year = seireki - 1925
    } else {
      result = 4
      year = seireki - 1988
    }
    warekiObj['seireki'] = seireki.toString()
    warekiObj['gengou'] = gengou[result]
    warekiObj['genMark'] = genMark[result]
    warekiObj['year'] = year.toString()
    warekiObj['month'] = month.toString()
    warekiObj['day'] = day.toString()
    return warekiObj
  }

  static _toSeirekiOnlyYear (warekiStr) {
    if (warekiStr.length < 2) {
      return ''
    }
    const wareki = Number(warekiStr.slice(1))
    if (isNaN(wareki)) {
      return ''
    }
    const gengou = warekiStr.slice(0,1).toUpperCase()
    let year
    switch (gengou) {
      case 'H':
        year = wareki + 1988
        break
      case 'S':
        year = wareki + 1925
        break
      case 'T':
        year = wareki + 1911
        break
      case 'M':
        year = wareki + 1867
        break
      default:
        return ''
    }
    return year.toString()
  }

  static _toSeireki (warekiStr) {
    let year, month, day
    const regexp = new RegExp(/(H|S|T|M)(\d+)(\.|\/)(\d+)(\.|\/)(\d+)/)
    let mr = warekiStr.match(regexp)
    if (mr !== null) {
      year = Util._toSeirekiOnlyYear(mr[1] + mr[2])
      month = mr[4]
      day = mr[6]
    } else {
      if (warekiStr.length !== 7) {
        return null
      }
      year = Util._toSeirekiOnlyYear(warekiStr.slice(0,3))
      month = warekiStr.slice(3,5)
      day = warekiStr.slice(5,7)
    }
    if (year === '') {
      return null
    }
    return {year, month, day}
  }

  static plusWareki (eightDigit) {
    // if year == 1 and not short:
    //     year = '元'
    const wareki = Util._toWareki(eightDigit)
    if (wareki !== null) {
      return `${wareki['genMark']}${wareki['year']}(${wareki['seireki']}).${wareki['month']}.${wareki['day']}`
    } else {
      return ''
    }
  }

  static toWareki (eightDigit) {
    let wareki = Util._toWareki(eightDigit)
    if (wareki !== null) {
      if (wareki['year'] === '1') {
        wareki['year'] = '元'
      }
      return `${wareki['gengou']}${wareki['year']}年${wareki['month']}月${wareki['day']}日`
    } else {
      return ''
    }
  }

  static toWarekiShort (eightDigit) {
    let wareki = Util._toWareki(eightDigit)
    if (wareki !== null) {
      return `${wareki['genMark']}${wareki['year']}.${wareki['month']}.${wareki['day']}`
    } else {
      return ''
    }
  }

  static toSeirekiHyphen (warekiStr) {
    const seirekiObj = Util._toSeireki(warekiStr)
    if (seirekiObj === null) {
      return ''
    } else {
      return (('0000' + seirekiObj.year).substr(-4)
            + '-' + ('00' + seirekiObj.month).substr(-2)
            + '-' + ('00' + seirekiObj.day).substr(-2))
    }
  }
}

export default Util
