import React from 'react'
import { withRouter } from 'react-router'
import CommentListView from './CommentListView'
import KeywordForm from './KeywordForm'
import Data from './Data'


class KeywordSearch extends React.Component {

  constructor (props) {
    super(props)
    this.state = {
      'keyword': props.match.params.keyword || '',
      'records': [],
      'keywordOfRecords': '',
      'query': 'ready'}
  }

  componentWillMount() {
    this.getDataFromKeyword(this.state.keyword)
  }

  componentWillReceiveProps (nextProps) {
    const keyword = nextProps.match.params.keyword || ''
    this.setState({keyword})
    if (keyword !== this.state.keywordOfRecords) {
      this.getDataFromKeyword(keyword)
    }
  }

  getDataFromKeyword (keyword) {
    if (keyword === '') {
      this.setState({'records': [], 'keywordOfRecords': ''})
    } else {
      Data.getDataFromKeyword(keyword,
        (e) => this.setState({'records': e, 'keywordOfRecords': keyword}))
    }
  }

  render () {
    return <div id="keysearch">
      <KeywordForm keyword={ this.state.keyword }
        onSubmit={ e => {
          this.props.history.push('/keysearch/' + e)
        }} />
      <CommentListView records={ this.state.records }
                       title={ this.state.keywordOfRecords } />
    </div>
  }
}

export default withRouter(KeywordSearch)
