import React from 'react'
import { Form, FormGroup, Label, Input, Button, Row, Col } from 'reactstrap'


class IDForm extends React.Component {

  constructor (props) {
    super(props)
    var result = this.caseidSplit(props.caseid)
    this.state = {
      'court': result[1] || '東京地裁',
      'gengou': result[2] || '平成',
      'year': result[3] || '',
      'kigou': result[4] || 'ワ',
      'num': result[5] || ''
    }
  }

  caseidSplit (caseid) {
    var result
    const regexp = new RegExp(/(.+裁)-(明治|大正|昭和|平成)(\d+)年（(.+)）第(\d+)号/)
    result = caseid.match(regexp)
    if (result === null) {
      result = Array(6)
      result.fill('')
    }
    return result
  }

  componentWillReceiveProps (nextProps) {
    var result = this.caseidSplit(nextProps.caseid)
    this.setState({
      'court': result[1] || '東京地裁',
      'gengou': result[2] || '平成',
      'year': result[3] || '',
      'kigou': result[4] || 'ワ',
      'num': result[5] || ''
    })
  }

  handleSelectChange (e) {
    this.setState({[e.target.name]: e.target.value})
  }

  handleCourtChange (e) {
    let kigou = 'ワ'
    const regexpH = new RegExp(/.+高裁/)
    if (regexpH.test(e.target.value)) {
      kigou = 'ネ'
    }
    this.setState({'court': e.target.value, 'kigou': kigou})
  }

  handleYearChange (e) {
    const num = Number(e.target.value)
    if (isNaN(num) || num === 0) {
      this.setState({'year': ''})
    }
    if (num > 0 && num < 100) {
      this.setState({'year': e.target.value})
    }
  }

  handleNumChange (e) {
    const num = Number(e.target.value)
    if (isNaN(num) || num === 0) {
      this.setState({'num': ''})
    }
    if (num > 0 && num < 1000000) {
      this.setState({'num': e.target.value})
    }
  }

  submit () {
    const caseid = this.state.court + '-' + this.state.gengou
      + this.state.year + '年（' + this.state.kigou + '）第'
      + this.state.num + '号'
    this.props.onSubmit(caseid)
  }

  render () {
    return <Form onSubmit={ e => e.preventDefault() }>
        <Row form>
          <Col md={5} sm={9} xs={30}>
            <FormGroup>
              <Label for="courtSelect">裁判所</Label>
              <Input type="select" name="court" id="courtSelect"
                onChange={ e => this.handleCourtChange(e) } value={this.state.court}>
                <optgroup label="地方裁判所">
                  <option>東京地裁</option>
                  <option>名古屋地裁</option>
                  <option>大阪地裁</option>
                </optgroup>
                <optgroup label="高等裁判所">
                  <option>東京高裁</option>
                  <option>大阪高裁</option>
                </optgroup>
              </Input>
            </FormGroup>
        </Col>
        <Col md={4} sm={7} xs={10}>
          <FormGroup>
            <Label for="gengouSelect">元号</Label>
            <Input type="select" name="gengou" id="gengouSelect"
              onChange={ e => this.handleSelectChange(e) } value={this.state.gengou}>
              <option>平成</option>
              <option>昭和</option>
            </Input>
          </FormGroup>
        </Col>
        <Col md={2} sm={3} xs={20}>
          <FormGroup>
            <Label for="year">年</Label>
            <Input type="text" name="year" id="year"
              onChange={ e => this.handleYearChange(e) } value={this.state.year}>
            </Input>
          </FormGroup>
        </Col>
        <Col md={3} sm={5} xs={10}>
          <FormGroup>
            <Label for="kigouSelect">記号</Label>
            <Input type="select" name="kigou" id="kigouSelect"
              onChange={ e => this.handleSelectChange(e) } value={this.state.kigou}>
              <option>ワ</option>
              <option>ネ</option>
            </Input>
          </FormGroup>
        </Col>
        <Col md={4} sm={6} xs={20}>
          <FormGroup>
            <Label for="num">番号</Label>
            <Input type="text" name="num" id="year"
              onChange={ e => this.handleNumChange(e) } value={this.state.num}>
            </Input>
          </FormGroup>
        </Col>
        <Col md={4} sm={4} className="align-self-end">
          <FormGroup>
            <Button onClick={ e => this.submit() }>検索</Button>
          </FormGroup>
        </Col>
      </Row>
    </Form>
  }
}

export default IDForm
