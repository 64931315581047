import Auth from '@aws-amplify/auth'
import DynamoDB from 'aws-sdk/clients/dynamodb'
import Util from './Util'


class Data {

  static getDBObj () {
    const p = new Promise((resolve, reject) => {
      Auth.currentCredentials()
        .then(credentials => {
          const db= new DynamoDB({
            region: 'ap-northeast-1',
            endpoint: process.env.REACT_APP_DYNAMODB_ENDPOINT,
            // sslEnabled: false,
            // sslEnabled: true,
            credentials: Auth.essentialCredentials(credentials),
          })
          resolve(db)
        })
        .catch(err => reject(err))
    })
    return p
  }

  static _getDataFromKeyword (db, keyword) {
    const p = new Promise((resolve, reject) => {
      const params = {
        ExpressionAttributeNames: {
          '#key' : 'key' // おそらくkeyは予約語なので置換する
        },
        ExpressionAttributeValues: {
          // ':s': {N: '2'},
          // ':e' : {N: '09'},
          ':k' : {S: keyword}
        },
        // KeyConditionExpression: 'Season = :s and Episode > :e',
        KeyConditionExpression: '#key = :k',
        // ProjectionExpression: 'Title, Subtitle',
        // FilterExpression: 'contains (Subtitle, :topic)',
        TableName: 'Commentaries',
        IndexName: 'KeySearch',
        ScanIndexForward: false, // 降順に結果を取得する
      }

      db.query(params, (err, data) => {
        if (err) {
          reject(err)
        } else {
          var accum = []
          data.Items.forEach(function(item) {
            accum.push({'title': item.title.S,
                        'date': item.n_value.N,
                        'judge_list': item.judge_list.L.map(e => e.S),
                        'word_list': item.word_list.L.map(e => e.S),
                        'link': item.link.S
            })
          })
          resolve(accum)
        }
      })
    })
    return p
  }

  static _getDataFromPeriod (db, start, end, isUnique) {
    const p = new Promise((resolve, reject) => {
      const params = {
        // ExpressionAttributeNames: {
        //   '#key' : 'key' // おそらくkeyは予約語なので置換する
        // },
        ExpressionAttributeValues: {
          ':s': {N: start},
          ':e' : {N: end},
          ':d' : {S: 'date'}
        },
        // KeyConditionExpression: 'Season = :s and Episode > :e',
        KeyConditionExpression: 's_value = :d and n_value BETWEEN :s AND :e',
        // ProjectionExpression: 'Title, Subtitle',
        // FilterExpression: 'contains (Subtitle, :topic)',
        TableName: 'Commentaries',
        IndexName: 'ValueSearch',
        ScanIndexForward: false, // 降順に結果を取得する
      }

      db.query(params, (err, data) => {
        if (err) {
          reject(err)
        } else {
          var accum = []
          data.Items.forEach(function(item) {
            accum.push({'title': item.title.S,
                        'date': item.n_value.N,
                        'judge_list': [item.key.S],
                        'word_list': item.word_list.L.map(e => e.S),
                        'link': item.link.S
            })
          })
          if (isUnique) {
            resolve(Data._deleteDuplication(accum))
          } else {
            resolve(accum)
          }
        }
      })
    })
    return p
  }

  static _deleteDuplication (origArray) {
    origArray.sort((a, b) => {
      if ( a.title > b.title ) {
        return -1
      }
      if ( a.title < b.title ) {
        return 1
      }
      if ( Number(a.date) > Number(b.date) ) {
        return 1
      }
      if ( Number(a.date) < Number(b.date) ) {
        return -1
      }
      return 0
    })
    let lastIndex = origArray.length - 1
    var newRecords = origArray.filter((elm, index, array) => {
      if (index === lastIndex) {
        return true
      }
      if (elm.title === array[index + 1].title) {
        // 1 つ目の配列に 2 つ目の配列をマージさせます
        Array.prototype.push.apply(array[index + 1].judge_list, elm.judge_list)
        return false
      }
      return true
    })
    newRecords.sort((a, b) => {
      if ( Number(a.date) > Number(b.date) ) {
        return -1
      }
      if ( Number(a.date) < Number(b.date) ) {
        return 1
      }
      return 0
    })
    return newRecords
  }

  static _getDataFromCaseID (db, caseid) {
    const p = new Promise((resolve, reject) => {
      const params = {
        ExpressionAttributeNames: {
          '#key' : 'key' // おそらくkeyは予約語なので置換する
        },
        ExpressionAttributeValues: {
          ':k' : {S: caseid}
        },
        KeyConditionExpression: '#key = :k',
        // ProjectionExpression: 'Title, Subtitle',
        // FilterExpression: 'contains (Subtitle, :topic)',
        TableName: 'Commentaries',
        IndexName: 'KeySearch',
        ScanIndexForward: false, // 降順に結果を取得する
      }

      db.query(params, (err, data) => {
        if (err) {
          reject(err)
        } else {
          var accum = []
          data.Items.forEach(function(item) {
            const ar = item.key.S.split('-')
            const jdata = ar[0] + '-'
              + Util.toWarekiShort(item.n_value.N.toString())
              + '-' + ar[1]
            accum.push({'title': item.title.S,
                        'date': item.n_value.N,
                        'judge_list': [jdata],
                        'word_list': item.word_list.L.map(e => e.S),
                        'link': item.link.S
            })
          })
          resolve(accum)
        }
      })
    })
    return p
  }

  static _initKeywordList (db) {
    const p = new Promise((resolve, reject) => {
      const params = {
        ExpressionAttributeValues: {
          ':k' : {S: '_keyword'}
        },
        KeyConditionExpression: 'title = :k',
        TableName: 'Commentaries',
        ScanIndexForward: false, // 降順に結果を取得する
      }

      db.query(params, (err, data) => {
        if (err) {
          reject(err)
        } else {
          Data.keywords = []
          data.Items.forEach(function(item) {
            Data.keywords.push({'word': item.key.S,
                                'num': item.n_value.N,
            })
          })
          // 並べ替え
          Data.keywords.sort(
            function(a,b){
              if( a.num < b.num ) return 1
              if( a.num > b.num ) return -1
              return 0})
          resolve()
        }
      })
    })
    return p
  }

  static getDataFromKeyword (keyword, callback) {
    const _keyword = '_' + keyword
    Data.getDBObj()
      .then((db) => Data._getDataFromKeyword(db, _keyword))
      .then((result) => callback(result))
  }

  static getDataFromDate (judgeDate, callback) {
    Data.getDBObj()
      .then((db) => Data._getDataFromPeriod(db, judgeDate, judgeDate))
      .then((result) => callback(result))
  }

  static getDataFromPeriod (start, end, isUnique, callback) {
    const startNum = Number(start)
    const endNum = Number(end)
    if (isNaN(startNum) || isNaN(endNum) || startNum > endNum) {
      console.log(`Error: start->"${start}", end=>"${end}"`)
      return
    }
    Data.getDBObj()
      .then((db) => Data._getDataFromPeriod(db, start, end, isUnique))
      .then((result) => callback(result))
  }

  static getDataFromCaseID (caseid, callback) {
    Data.getDBObj()
      .then((db) => Data._getDataFromCaseID(db, caseid))
      .then((result) => callback(result))
  }

  static initKeywordList () {
    Data.getDBObj()
      .then((db) => Data._initKeywordList(db))
  }

}

Data.keywords = []


export default Data
