import React from 'react'
import { Row, Col } from 'reactstrap'
import { Link } from 'react-router-dom'
import { generate as shortid } from 'shortid'
import Util from './Util'


class CommentListView extends React.Component {

  constructor (props) {
    super(props)
    this.state = {
      'title': props.title,
      'records': this.addNumber(props.records)}
  }

  addNumber (records) {
    const counter = Util.counter(1)
    return records.map( (e) => {
      e.num = counter()
      return e
    })
  }

  componentWillReceiveProps (nextProps) {
    this.setState({
      'title': nextProps.title,
      'records': this.addNumber(nextProps.records)})
  }

  render () {
    const numParam = {md: '1', className: 'num py-1 px-0 align-self-center text-right', key: shortid()}
    var titleParam = {md: '11', className: 'tbody py-1 align-self-center', key: shortid()}
    const dateParam = {md: '5', 'data-desc': '裁判日', className: 'tbody py-1 align-self-center', key: shortid()}
    const keywordParam = {md: '13', 'data-desc': 'キーワード', className: 'tbody py-1 align-self-center', key: shortid()}

    const data = this.state.records.map(function (e) {
      const jList = e.judge_list.map( t => {
        const ar = t.split('-')
        return <div className="d-inline-block pr-2" key={ shortid() }>
            <div className="d-inline-block" key={ shortid() }>
              { ar[0] }
            </div>
            <div className="d-inline-block" key={ shortid() }>
              { ar[1] }
            </div>
          </div>
      })
      const numElem = React.createElement(Col, numParam, e.num)
      titleParam['data-desc'] = 'No.' + e.num
      const titleLink = <Link to={'/comment/' + e.link }>{ e.title }</Link>
      const titleElem = React.createElement(Col, titleParam, titleLink)
      const dateElem = React.createElement(Col, dateParam, jList)
      const keywordElem = React.createElement(Col, keywordParam, e.word_list.join(', '))
      return React.createElement(Row, {className: 'mx-0', key: shortid()},
        [numElem, titleElem, dateElem, keywordElem])
    })
    // const tori="md"
    // const tori2 = React.createElement(Col, {[tori]: "12", className: titleStyle}, "タイトル2")
    const numHead = React.createElement(Col, numParam, 'No.')
    const titleHead = React.createElement(Col, titleParam, 'タイトル')
    const dateHead = React.createElement(Col, dateParam, '裁判日')
    const keywordHead = React.createElement(Col, keywordParam, 'キーワード')
    const head = React.createElement(Row, {className: 'mx-0 head', key: shortid()},
      [numHead, titleHead, dateHead, keywordHead])
    const title = <Row><Col>
        { this.state.title }：{ this.state.records.length }件
      </Col></Row>
    return <div className="db">
            <div className="db_title">
              { this.state.title !== '' && title }
            </div>
            <div className="db_content">
              { this.state.records.length !== 0 && head }
              { data }
            </div>
          </div>
  }
}

export default CommentListView
