import React, { Component } from 'react'
import { generate as shortid } from 'shortid'
// import Auth from '@aws-amplify/auth'
// import API from '@aws-amplify/api'

//test data
// import { data } from './sample_data'

import Storage from '@aws-amplify/storage'

//Storage.configure
Storage.configure({
  Storage: {
    bucket: 'net.hanketsu.contents', //REQUIRED -  Amazon S3 bucket
    region: 'ap-northeast-1', //REQUIRED -  bucket region
  }
})


class Commentary extends Component {

  constructor (props) {
    super(props)
    this.state = {
      objKey: props.match.params.objKey || '',
      contents: {}
    }
  }

  getJsonFromS3 (objectKey) {
    if (this.state.objKey !== '') {
      Storage.get(objectKey, {download: true})
          .then(result => {
            this.setState({
              objKey: objectKey,
              contents: JSON.parse(result.Body)})
          })
          .catch(err => {
            console.log(err)
            this.setState({
              objKey: '',
              contents: {}})})
    }
  }

  componentWillMount () {
    this.getJsonFromS3(this.state.objKey)
  }

  componentWillReceiveProps (nextProps) {
    const newObjKey = nextProps.match.params.objKey || ''
    if (newObjKey !== this.state.objKey) {
      this.getJsonFromS3(newObjKey)
    }
  }

  paragraphArrayToHTML (key) {
    if (!(key in this.state.contents)) { return }
    return this.state.contents[key].map(function (chunks) {
      var html = []
      var r
      for(let i = 0; i < chunks.length; i++) {
        for(let style of Object.keys(chunks[i])) {
          if(style === 'normal') {
            html.push(chunks[i][style])
          } else if((r = style.match(/heading_(\d+)/))) {
            return React.createElement('h' + r[1], {key: shortid()}, chunks[i][style])
          }
        }
      }
      return <div className="paragraph" key={ shortid() }>{ html }</div>
    })
  }

  render () {
    // headKeys - 表示する項目
    const headKeys = ['title', 'caseIDs', 'keywords', 'abstract', 'issues', 'judgment', 'comment', 'references']
    const func = this.paragraphArrayToHTML.bind(this)
    return headKeys.map(function (key) {
      return <div id={ key } key={ shortid() }>{ func(key) }</div>
    })
  }
}

export default Commentary
