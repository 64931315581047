import React from 'react'
import Auth from '@aws-amplify/auth'
import { Hub } from '@aws-amplify/core'
import OAuthButton from './OAuthButton'
import Header from './Header'
import { Container, Row, Col } from 'reactstrap'
import { Switch, Route } from 'react-router-dom'
import Search from './Search'
import Data from  './Data'
import Admin from './Admin'
import './App.scss'

// Cognito Hosted UI configuration
const oauth = {
    // Domain name
    domain : 'auth.hanketsu.net',
    // Authorized scopes
    scope : ['openid', 'aws.cognito.signin.user.admin'],
    // Callback URL
    redirectSignIn : process.env.REACT_APP_REDIRECT_URL,
    // redirectSignIn : 'https://hanketsu.net',
    // redirectSignIn : 'http://localhost:3000',
    // Sign out URL
    redirectSignOut : process.env.REACT_APP_REDIRECT_URL,
    // redirectSignOut : 'https://hanketsu.net',
    // redirectSignOut : 'http://localhost:3000',
    // 'code' for Authorization code grant,
    // 'token' for Implicit grant
    responseType: 'code',
    // optional, for Cognito hosted ui specified options
    options: {
        // Indicates if the data collection is enabled to support Cognito
        // advanced security features. By default, this flag is set to true.
        AdvancedSecurityDataCollectionFlag : true
    }
}

//Amplify.configure
Auth.configure({
    Auth: {
      identityPoolId: 'ap-northeast-1:fe2aa88b-3909-4627-832c-f270ced6e469',
      // REQUIRED - Amazon Cognito Region
      region: 'ap-northeast-1',
      //OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: 'ap-northeast-1_EuGMNluvi',
      //OPTIONAL - Amazon Cognito Web Client ID
      userPoolWebClientId: '1v0kbap8amnfc2oltrj61v5sqe',
      oauth: oauth
    }
})

class App extends React.Component {
  constructor(props) {
    super(props)
    this.onHubCapsule = this.onHubCapsule.bind(this)
    this.signOut = this.signOut.bind(this)
    // let the Hub module listen on Auth events
    Hub.listen('auth', this)
    this.state = {
      authState: 'loading',
      name: ''
    }
  }

  componentDidMount() {
    // check the current user when the App component is loaded
    Auth.currentAuthenticatedUser().then(user => {
      this.setState({authState: 'signedIn', name: user.username})
      this.deleteCode()
      Data.initKeywordList()
    }).catch(e => {
      console.log(e)
      this.setState({authState: 'signIn', name: ''})
    })
  }

  deleteCode () {
    // サインイン後の「?code=～」を消す処理
    if (/\?code=/.test(window.location.href)){
          window.history.replaceState("", "", '/#/')
    }
  }

  onHubCapsule(capsule) {
    // The Auth module will emit events when user signs in, signs out, etc
    const { channel, payload } = capsule
    if (channel === 'auth') {
      switch (payload.event) {
        case 'signIn':
          console.log('signed in')
          Auth.currentAuthenticatedUser().then(user => {
            this.setState({authState: 'signedIn', name: user.username})
            this.deleteCode()
            Data.initKeywordList()
          }).catch(e => {
            console.log(e)
          })
          break
        case 'signIn_failure':
          console.log('not signed in')
          this.setState({authState: 'signIn', name: ''})
          break
        default:
          break
      }
    }
  }

  signOut() {
    Auth.signOut().then(() => {
      this.setState({authState: 'signIn', name: ''})
    }).catch(e => {
      console.log(e)
    })
  }

  render() {
    const { authState } = this.state
    return (
      <Container fluid>
        <Row noGutters>
          <Col>
            {authState === 'loading' && (<div>loading...</div>)}
            {authState === 'signIn' && <OAuthButton />}
            {authState === 'signedIn' &&
              <Header userName={ this.state.name } signOut={ this.signOut } />}
          </Col>
        </Row>
        {authState === 'signedIn' && (
          <div>
          <div className="row">
            <div className="col">
            <Switch>
              <Route path="/admin" component={ Admin } />
              <Route component={ Search } />
            </Switch>
            </div>
          </div>

          </div>
        )}
      </Container>
    )
  }
}

export default App
