import React from 'react'
import Auth from '@aws-amplify/auth'
import DynamoDB from 'aws-sdk/clients/dynamodb'
import { withRouter } from 'react-router'
import UploadWord from './UploadWord'


class Admin extends React.Component {

  constructor (props) {
    super(props)
    this.state = {
      text: 'admin',
      'records': [],
      'fromDate': props.match.params.fromDate || '',
      'toDate': props.match.params.toDate || ''
    }
  }

  componentWillMount() {
  }

  componentWillReceiveProps (nextProps) {
    let isUnique
    if (nextProps.match.params.isUnique === 'true') {
      isUnique = true
    } else {
      isUnique = false
    }
    const start = nextProps.match.params.fromDate || ''
    const end = nextProps.match.params.toDate || ''
    if (start === this.state.fromDate
      && end === this.state.toDate
      && isUnique === this.state.isUnique) {
      return
    } else {
      this.getDataFromPeriod(start, end, isUnique)
    }
  }

  static getDBObj () {
    const p = new Promise((resolve, reject) => {
      Auth.currentCredentials()
        .then(credentials => {
          const db= new DynamoDB({
            region: 'ap-northeast-1',
            endpoint: process.env.REACT_APP_DYNAMODB_ENDPOINT,
            // sslEnabled: false,
            // sslEnabled: true,
            credentials: Auth.essentialCredentials(credentials),
          })
          resolve(db)
        })
        .catch(err => reject(err))
    })
    return p
  }

  static _getDataFromKeyword (db, keyword) {
    const p = new Promise((resolve, reject) => {
      const params = {
        ExpressionAttributeNames: {
          '#key' : 'key' // おそらくkeyは予約語なので置換する
        },
        ExpressionAttributeValues: {
          // ':s': {N: '2'},
          // ':e' : {N: '09'},
          ':k' : {S: keyword}
        },
        // KeyConditionExpression: 'Season = :s and Episode > :e',
        KeyConditionExpression: '#key = :k',
        // ProjectionExpression: 'Title, Subtitle',
        // FilterExpression: 'contains (Subtitle, :topic)',
        TableName: 'Commentaries',
        IndexName: 'KeySearch',
        ScanIndexForward: false, // 降順に結果を取得する
      }

      db.query(params, (err, data) => {
        if (err) {
          reject(err)
        } else {
          var accum = []
          data.Items.forEach(function(item) {
            accum.push({'title': item.title.S,
                        'date': item.n_value.N,
                        'judge_list': item.judge_list.L.map(e => e.S),
                        'word_list': item.word_list.L.map(e => e.S),
                        'link': item.link.S
            })
          })
          resolve(accum)
        }
      })
    })
    return p
  }

  render () {
    return (
      <div>
        <h2>Word原稿アップロード</h2>
        <UploadWord />
      </div>
    )
  }

}

export default withRouter(Admin)
