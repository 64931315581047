import React from 'react'
import Autosuggest from 'react-autosuggest'
import Data from './Data'


const theme = {
  container:                'react-autosuggest__container',
  containerOpen:            'react-autosuggest__container--open',
  // input:                    'react-autosuggest__input',
  input:                    'form-control',
  inputOpen:                'react-autosuggest__input--open',
  inputFocused:             'react-autosuggest__input--focused',
  suggestionsContainer:     'react-autosuggest__suggestions-container',
  suggestionsContainerOpen: 'react-autosuggest__suggestions-container--open',
  // suggestionsList:          'react-autosuggest__suggestions-list',
  suggestionsList:          'ki_list',
  suggestion:               'react-autosuggest__suggestion',
  suggestionFirst:          'react-autosuggest__suggestion--first',
  // suggestionHighlighted:    'react-autosuggest__suggestion--highlighted',
  suggestionHighlighted:    'ki_highlighted',
  sectionContainer:         'react-autosuggest__section-container',
  sectionContainerFirst:    'react-autosuggest__section-container--first',
  sectionTitle:             'react-autosuggest__section-title'
}

// Teach Autosuggest how to calculate suggestions for any given input value.
const getSuggestions = value => {
  const inputValue = value.trim().toLowerCase().replace(/\\/g,'')
  const inputLength = inputValue.length
  // const regexp = new RegExp(inputValue)

  return inputLength === 0 ? [] : Data.keywords.filter(lang =>
    // lang.word.toLowerCase().slice(0, inputLength) === inputValue
    // regexp.test(lang.word.toLowerCase())
    lang.word.toLowerCase().includes(inputValue)
  )
}

// When suggestion is clicked, Autosuggest needs to populate the input
// based on the clicked suggestion. Teach Autosuggest how to calculate the
// input value for every given suggestion.
const getSuggestionValue = suggestion => suggestion.word

// Use your imagination to render suggestions.
const renderSuggestion = suggestion => (
  <span>
    { suggestion.num }件：{suggestion.word}
  </span>
)


class KeywordInput extends React.Component {

  constructor (props) {
    super(props)
    // Autosuggest is a controlled component.
    // This means that you need to provide an input value
    // and an onChange handler that updates this value (see below).
    // Suggestions also need to be provided to the Autosuggest,
    // and they are initially empty because the Autosuggest is closed.
    this.state = {
      value: props.keyword,
      suggestions: []
    }
  }

  componentWillReceiveProps (nextProps) {
    this.setState({value: nextProps.keyword})
  }

  onChange = (event, { newValue }) => {
    this.setState({
      value: newValue
    })
    if (this.props.onChange) {
      this.props.onChange({target: this, value: newValue})
    }
  }

  // Autosuggest will call this function every time you need to update suggestions.
  // You already implemented this logic above, so just use it.
  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: getSuggestions(value)
    })
  }

  // Autosuggest will call this function every time you need to clear suggestions.
  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    })
  }

  render() {
    const { value, suggestions } = this.state

    // Autosuggest will pass through all these props to the input.
    const inputProps = {
      placeholder: 'キーワードを入力',
      value,
      onChange: this.onChange,
      onKeyPress: this.props.onKeyPress
    }

    // Finally, render it!
    return (
      <Autosuggest
        theme={theme}
        suggestions={suggestions}
        onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
        onSuggestionsClearRequested={this.onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        inputProps={inputProps}
      />
    )
  }

}

export default KeywordInput
