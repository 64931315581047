import React from 'react'
import { Row, Col, Button } from 'reactstrap'
import { Link } from 'react-router-dom'


class Header extends React.Component {

  constructor (props) {
    super(props)
    this.state = {
      name: props.userName

    }
  }

  signOut () {
    if(this.props.signOut) {
      this.props.signOut()
    }
  }

  render () {
    return (
      <div>
      <Row className="pt-2">
        <Col className="align-self-center">
          <Link to='/' id="title-logo">判例解説検索システム</Link>
        </Col>
        <Col className="col-auto align-self-end">
          <Row>
            <Col><Link to='/admin'>管理ページへ</Link></Col>
          </Row>
          <Row>
            <Col>User: {this.props.userName}</Col>
          </Row>
        </Col>
        <Col className="col-auto">
          <Button color="secondary" size="sm" onClick={ (e) => this.signOut() }>
            sign out
          </Button>
        </Col>
      </Row>
      <hr id="header_bottom"/>
      </div>
    )
  }
}

export default Header
